import React from "react";
// import me from "./myImage.png";
import surferGirl from "../../assets/surfer_girl.png";
import emotes from "../../assets/Emotes.png";
import watercolor from "../../assets/watercolor.jpg";
import portait from "../../assets/portait.jpg";
import wallart from "../../assets/wallart.jpg";
import branding from "../../assets/branding.jpg";
import chalkart from "../../assets/chalkart.jpg";
import info from "../../assets/info.jpg";
import lettering from "../../assets/lettering.jpg";
import illustration_book from "../../assets/illustration_book.jpg";
import book from "../../assets/bookcover.jpg";
import comic from "../../assets/Comic.jpg";
import ui from "../../assets/UI.jpg";
import styles from "./Content.module.css";
import { Button } from "@mui/material";
// import InstagramIcon from "@mui/icons-material/Instagram";
import CurlyArrow from "../../assets/CurlyArrow.svg";
export const Content = () => {
  return (
    <>
      <div className={styles.Section}>
        <div className={styles.SectionFlex}>
          <div>
            <div className={styles.SectionGreenLeft} />
            <div className={styles.SectionContentHeader}>
              <div className={styles.SectionHeadline}>
                {" "}
                <span className={styles.IntroText}>
                  <span className={styles.Highlighted}>Happiness</span> by
                  Design
                </span>
              </div>
              <div className={styles.SectionContentText}>
                <div>
                  {" "}
                  all sorts of visual <br />
                  design work{" "}
                </div>
                <img
                  className={styles.CurlyArrow}
                  src={CurlyArrow}
                  alt="Curly Arrow"
                />
              </div>
              <div className={styles.ButtonContainer}>
                <Button
                  sx={{
                    borderRadius: 50,
                    fontWeight: 900,
                    color: "black",
                    backgroundColor: "#CBD6FF",
                  }}
                  variant={"contained"}
                >
                  GET YOUR OWN DESIGN
                </Button>
              </div>
            </div>
          </div>
          <img
            className={styles.SurferGirl}
            src={surferGirl}
            alt="SurferGirl"
          />
        </div>
        <div className={styles.Divider}></div>
      </div>
      <div className={styles.Section}>
        <div className={styles.SectionContent}>
          <div className={styles.SectionHeadline}>My Work</div>
        </div>
        <div className={styles.Gallery}>
          <div className={styles.GalleryContainer}>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={watercolor}
                alt="Watercolors"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img className={styles.GalleryItemImage} src={ui} alt="UI" />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={portait}
                alt="Portrait"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={comic}
                alt="Portrait"
              />
            </div>
          </div>
          <div className={styles.GalleryContainer}>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={wallart}
                alt="Portrait"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={branding}
                alt="Portrait"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={chalkart}
                alt="Portrait"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={emotes}
                alt="Emotes"
              />
            </div>
          </div>
          <div className={styles.GalleryContainer}>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={book}
                style={{ width: "150%" }}
                alt="Emotes"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={info}
                alt="Emotes"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={illustration_book}
                alt="Emotes"
              />
            </div>
            <div className={styles.GalleryItem}>
              <img
                className={styles.GalleryItemImage}
                src={lettering}
                alt="Emotes"
              />
            </div>
          </div>
        </div>
        <div className={styles.Divider}></div>
      </div>
    </>
  );
};
