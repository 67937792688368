import React from "react";
import { Content } from "./components/content/Content";
import styles from "./App.module.css";

function App() {
  return (
    <div className="Container">
      <div className={styles.UpperBar}>
        <div className={styles.Menu}>My Work | About Me | Contact</div>
      </div>
      <div className="App">
        <div className={styles.Headline}>LINE ART VISUAL DESIGN</div>
        <Content />
      </div>
    </div>
  );
}

export default App;
